/* eslint-disable react-hooks/exhaustive-deps */
import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
// import { render } from 'datocms-structured-text-to-html-string';
import { Layout } from '@solublestudio/gatsby-theme-soluble-source';

import { Container } from '@solublestudio/soluto-design-system';
import Section from '@ds/components/shared/Section';
import HeroSection from '../../components/HeroSection';

const Blog = ({ data }) => {
    // const option = {};
    // const html = useMemo(() => render(content, option), [content, option]);
    const title = useMemo(() => data?.datoCmsBlog?.titulo ?? '', [data]);
    const date = useMemo(() => data?.datoCmsBlog?.date ?? '', [data]);
    const description = useMemo(
        () => data?.datoCmsBlog?.seoSettings?.description ?? '',
        [data],
    );
    const content = useMemo(() => data?.datoCmsBlog?.contenido ?? '', [data]);
    const categoriesMap = useMemo(
        () =>
            data?.datoCmsBlog?.categories?.map((item) => item.name).join(' ') ??
            '',
        [data],
    );
    const image = useMemo(
        () => data?.datoCmsBlog?.coverImage?.fluid?.src ?? '',
        [data],
    );

    return (
        <Layout isDark={true} navbarTransparent={true}>
            <>
                <html lang="es" />
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="image" content={image} />
                {/* <meta name="twitter:title" content={Seo.title} />
                <meta name="twitter:url" content={Seo.url} />
                <meta name="twitter:description" content={Seo.description} />
                <meta name="twitter:image" content={Seo.image} />
                <meta name="twitter:creator" content={Seo.twitterUsername} />
                <meta property="og:title" content={Seo.title} />
                <meta property="og:url" content={Seo.url} />
                <meta property="og:description" content={Seo.description} />
                <meta property="og:image" content={image} />
                <meta property="og:site_name" content={Seo.url} />
                <meta property="og:type" content="marketplace" /> */}
            </>
            <HeroSection
                title={title}
                subtitle={data?.datoCmsBlog?.author?.name}
                pretitle={String(categoriesMap)}
                text={data?.datoCmsBlog?.author?.name}
            />
            <Section>
                <Container>
                    <h4
                        style={{
                            marginTop: 10,
                        }}>
                        {categoriesMap}
                    </h4>
                    <span>{date}</span>
                    <div
                        style={{
                            marginTop: 50,
                        }}
                        dangerouslySetInnerHTML={{
                            __html: content,
                        }}
                    />
                </Container>
            </Section>
        </Layout>
    );
};

export default Blog;

export const query = graphql`
    query ($id: String!) {
        datoCmsBlog(id: { eq: $id }) {
            id
            titulo
            date
            categories {
                name
            }
            author {
                name
            }
            contenido
            coverImage {
                fluid {
                    ...GatsbyDatoCmsFluid
                }
            }
            seoSettings {
                description
                title
            }
            slug
        }
    }
`;
